import {
  Avatar,
  Divider,
  HStack,
  Heading,
  Image,
  Link,
  Text,
  VStack,
  Tag,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
  NavLink, useHistory, useLocation,
} from 'react-router-dom';
import api from '../../../services/api';
import Indique from '../../../assets/img/platform/navigation/indique.svg';
import ClassDocsIcon from '../../../assets/img/platform/navigation/class-docs-icon-blue.svg';
import HomeIcon from '../../../assets/img/platform/navigation/home-icon.svg';
import HomeIconBlue from '../../../assets/img/platform/navigation/home-icon-blue.svg';
import MediaIcon from '../../../assets/img/platform/navigation/media-icon.svg';
import MediaIconBlue from '../../../assets/img/platform/navigation/media-icon-blue.svg';
import HistoryIcon from '../../../assets/img/platform/navigation/history-icon.svg';
import HistoryIconBlue from '../../../assets/img/platform/navigation/history-icon-blue.svg';
import TeacherIcon from '../../../assets/img/platform/navigation/teacher-icon.svg';
import TeacherIconBlue from '../../../assets/img/platform/navigation/teacher-icon-blue.svg';
import CreditCardIcon from '../../../assets/img/platform/navigation/credit-card-icon.svg';
import CreditCardIconBlue from '../../../assets/img/platform/navigation/credit-card-icon-blue.svg';
import ChatBubble from '../../../assets/img/platform/navigation/chat-bubble.svg';
import ChatBubbleBlue from '../../../assets/img/platform/navigation/basil_chat-outline.svg';
import LogoutIcon from '../../../assets/img/platform/navigation/logout.svg';
import LogoutIconBlue from '../../../assets/img/platform/navigation/logout-blue.svg';
import socket from '../../../services/socket';
import { useStores } from '../../../stores';
import NotificationBell from '../../NotificationBell';
import PlanUnpauseModal from '../../PlanUnpauseModal';
import DropdownNav from '../../UserNavigation/DropdownNav';
import style from './style.module.scss';
import NavbarDrawerStudent from '../../NavbarDrawerStudent';
import NavbarStudentMobile from '../../NavbarStudentMobile';
import notify from '../../../utils/notify';
import CustomToastError from '../../CustomToastError';

export default function NavbarStudent({ children }) {
  const {
    userStore:
    {
      handleClearUserData, userData, setUserData, token,
    },
    navStore:
    { currentNav, setCurrentNav },
    planUnpauseStore:
    {
      useCredits, setUseCredits,
      removeUseCredits,
    },
    globalStatesStore: {
      openPromoModal,
      openPromoSuccess,
      openPromoModalTeacher,
    },
  } = useStores();
  const [openPlanUnpause, setOpenPlanUnpause] = useState(false);
  const [openIsUnpausing, setOpenIsUnpausing] = useState(false);

  const fullName = `${userData.firstName} ${userData.lastName}`;

  const location = useLocation();

  const history = useHistory();

  const {
    isPaused,
    isUnpausing,
    isPausingCredits,
  } = userData;

  useEffect(() => {
    if (!userData.isUnpausing) {
      socket.on(`${userData.id}-unpause-plan`, ({ pauseStatus, unpausingStatus }) => {
        setUserData({ ...userData, isPaused: pauseStatus });
        setUserData({ ...userData, isUnpausing: unpausingStatus });

        if (!isPaused && !isUnpausing) {
          return setOpenPlanUnpause(false);
        }
        if (!isPaused && isUnpausing) {
          setOpenPlanUnpause(false);
          return setOpenIsUnpausing(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (isPaused) {
      if (!isUnpausing) {
        return setOpenPlanUnpause(true);
      }
      if (isUnpausing) {
        return setOpenIsUnpausing(true);
      }
    }
  }, []);

  const promoStorage = localStorage.getItem('promo-modal-notification');

  function openUnpauseModal() {
    setUseCredits(false);
    setOpenPlanUnpause(true);
  }

  function handleLogout() {
    handleClearUserData();
    history.push('/');
  }

  function handleAddHours(e) {
    if (e === 'renew') {
      history.push('/profile/student/add-credits');
    }
    if (e === 'subscribe') {
      setUserData({ ...userData, isShorts: false });
      history.push('/subscription');
    }
  }

  async function recommendStudent() {
    try {
      const response = await api.post('/recommend', {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status > 205) {
        notify(<CustomToastError
          toastText={response.data}
        />);
        return;
      }

      return window.open('https://unmazebr.wixsite.com/website-2', '_blank');
    } catch (error) {
      const { request } = error;

      if (request) {
        notify(<CustomToastError
          toastText={request.response}
        />);
        return;
      }

      return notify(<CustomToastError
        toastText={error.message}
      />);
    }
  }

  return (
    <div className={style.main__body}>
      <div className={style.responsive__navbar}>
        <NavbarStudentMobile />
      </div>
      <div className={style.navbar__container}>
        <VStack className={style.nav__holder}>
          <VStack alignItems="center" w="100%">
            <Avatar src={userData?.avatar} boxSize="52px" />
            <Heading as="h5" size="sm" color="#06097D">
              {fullName}
            </Heading>
          </VStack>

          <VStack className={style.navs}>
            <VStack w="100%" spacing={2}>
              <VStack w="100%" spacing={2} pl={5}>
                <Divider w="85%" borderColor="#06097D" marginTop={3} />
                <Text
                  alignSelf="start"
                  fontWeight="bold"
                  fontSize="16px"
                  ml={3}
                >
                  Principal
                </Text>

                <NavLink
                  to={`/home/${userData.userType.toLowerCase()}`}
                  className={style.a}
                >
                  <HStack
                    marginLeft="15px"
                    w="85%"
                    borderRadius={5}
                    spacing={2}
                    p={2}
                    bg={location.pathname === `/home/${userData.userType.toLowerCase()}`
                      ? '#06097D' : '#fff'}
                  >
                    <Image
                      src={location.pathname === `/home/${userData.userType.toLowerCase()}`
                        ? HomeIcon : HomeIconBlue}
                      boxSize="24px"
                    />
                    <Text
                      color={location.pathname === `/home/${userData.userType.toLowerCase()}`
                        ? '#fff' : '#06097D'}
                    >
                      Início
                    </Text>
                  </HStack>
                </NavLink>
                {(userData.userType === 'Student' && userData?.classLink) && (
                <Link
                  href={userData.classLink}
                  target="_blank"
                  rel="noreferrer"
                  w="100%"
                >
                  <HStack
                    marginLeft="15px"
                    w="85%"
                    borderRadius={5}
                    spacing={2}
                    p={2}
                  >
                    <Image src={ClassDocsIcon} boxSize="24px" />
                    <Text> Minhas aulas</Text>
                  </HStack>
                </Link>
                )}

                <NavLink
                  to="/platform/content-area"
                  style={{ width: '100%' }}
                >
                  <HStack
                    marginLeft="15px"
                    w="85%"
                    borderRadius={5}
                    spacing={2}
                    p={2}
                    bg={location.pathname === '/platform/content-area'
                      ? '#06097D' : '#fff'}
                  >
                    <Image
                      src={location.pathname === '/platform/content-area'
                        ? MediaIcon : MediaIconBlue}
                      boxSize="24px"
                    />
                    <Text
                      color={location.pathname === '/platform/content-area'
                        ? '#fff' : '#06097D'}
                    >
                      Área de conteúdo
                    </Text>
                  </HStack>
                </NavLink>
                <NavLink
                  to="/history"
                  style={{ width: '100%' }}
                >
                  <HStack
                    marginLeft="15px"
                    w="85%"
                    borderRadius={5}
                    spacing={2}
                    p={2}
                    bg={location.pathname === '/history'
                      ? '#06097D' : '#fff'}
                  >
                    <Image
                      src={location.pathname === '/history'
                        ? HistoryIcon : HistoryIconBlue}
                      boxSize="24px"
                    />
                    <Text
                      color={location.pathname === '/history'
                        ? '#fff' : '#06097D'}
                    >
                      Histórico
                    </Text>
                  </HStack>
                </NavLink>
                <NavLink
                  to="/teacher-info"
                  style={{ width: '100%' }}
                >
                  <HStack
                    marginLeft="15px"
                    w="85%"
                    borderRadius={5}
                    spacing={2}
                    p={2}
                    bg={location.pathname === '/teacher-info'
                      ? '#06097D' : '#fff'}
                  >
                    <Image
                      src={location.pathname === '/teacher-info'
                        ? TeacherIcon : TeacherIconBlue}
                      boxSize="24px"
                    />
                    <Text
                      color={location.pathname === '/teacher-info'
                        ? '#fff' : '#06097D'}
                    >
                      Meu professor
                    </Text>
                  </HStack>
                </NavLink>

                <Link
                  target="_blank"
                  rel="noreferrer"
                  w="100%"
                  onClick={() => recommendStudent()}
                >
                  <HStack
                    marginLeft="15px"
                    w="85%"
                    borderRadius={5}
                    spacing={2}
                    p={2}
                  >
                    <Image src={Indique} boxSize="24px" />
                    <Text> Indique e ganhe</Text>
                  </HStack>
                </Link>

              </VStack>
            </VStack>
          </VStack>
        </VStack>

        <VStack className={style.navs}>
          <VStack w="100%" pl={5}>
            <Text
              alignSelf="start"
              ml={3}
              mt={3}
              fontWeight="bold"
              fontSize="16px"
            >
              Conta
            </Text>
            {(useCredits && isPaused) && (
              <div className={style['open-plan-modal']}>
                <div className={style['button-container']}>
                  <button
                    className={style['open-modal-button']}
                    onClick={() => openUnpauseModal()}
                    type="button"
                  >
                    Reativar plano
                  </button>
                </div>
              </div>
            )}
            {/* {userData.userType === 'Student' && (
              <Button
                className={style.button__green}
                color="white"
                backgroundColor="#47C13C"
                fontSize="sm"
                h="37px"
                marginBottom={5}
                value={!userData.plan ? 'subscribe' : 'renew'}
                onClick={(e) => handleAddHours(e.target.value)}
              >
                {!userData.plan ? 'Ativar professor' : 'Solicitar renovação'}
              </Button>

            )} */}
            <NavLink
              to={userData.plan ? `/profile/${userData.userType.toLowerCase()}` : '/subscription'}
              style={{ width: '100%' }}
            >
              <HStack
                marginLeft="15px"
                w="85%"
                borderRadius={5}
                spacing={2}
                p={2}
                bg={location.pathname === `/profile/${userData.userType.toLowerCase()}`
                  ? '#06097D' : '#fff'}
              >
                <Image
                  src={location.pathname === `/profile/${userData.userType.toLowerCase()}`
                    ? CreditCardIcon : CreditCardIconBlue}
                  boxSize="24px"
                />
                <Text
                  color={location.pathname === `/profile/${userData.userType.toLowerCase()}`
                    ? '#fff' : '#06097D'}
                >
                  Minha assinatura
                </Text>
              </HStack>
            </NavLink>
            <DropdownNav />
            <HStack
              w="100%"
              pl={2}
            >
              <NotificationBell />
            </HStack>
            <Link
              href="https://midnight-archduke-8c8.notion.site/D-vidas-frequentes-f318b33bfa2e4f82a062e91ad24e7b30"
              target="_blank"
              rel="noreferrer"
              className={style.a}
            >
              <HStack
                marginLeft="15px"
                w="85%"
                borderRadius={5}
                spacing={2}
                p={2}
              >
                <Image src={ChatBubbleBlue} boxSize="24px" />
                <Text>
                  Suporte
                </Text>
              </HStack>
            </Link>
            <HStack
              as="button"
              w="100%"
              h="32px"
              pl={2}
              onClick={() => handleLogout()}
            >
              <Image src={LogoutIconBlue} boxSize="24px" ml="15px" />
              <Text
                fontSize="14px"
                fontWeight={600}
                w="100%"
                textAlign="start"
              >
                {userData.userType === 'Student' ? 'Sair' : 'Logout'}

              </Text>
            </HStack>
          </VStack>
        </VStack>
      </div>

      <div className={style.main__content}>
        {(openPlanUnpause && !useCredits)
          && (
            <PlanUnpauseModal
              openPlanUnpause={openPlanUnpause}
              setOpenPlanUnpause={setOpenPlanUnpause}
            />
          )}

        {openIsUnpausing
          && (
            <PlanUnpauseModal
              setOpenIsUnpausing={setOpenIsUnpausing}
              isUnpausingPlan
            />
          )}

        {/* {(userData.userType === 'Student' && !openPromoModal) && <PromoHeader />}
        {(userData.userType === 'Teacher'
          && !openPromoModalTeacher) && <PromoHeaderTeacher />} */}

        {children}
      </div>

    </div>
  );
}
