import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  HStack,
  Image,
  Link,
  Tag,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MdOutlinePermMedia } from 'react-icons/md';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import Star from '../../../assets/img/platform/navigation/star.svg';
import StarBlue from '../../../assets/img/platform/navigation/star-blue.svg';
import StudentIcon from '../../../assets/img/platform/navigation/students-icon.svg';
import StudentIconBlue from '../../../assets/img/platform/navigation/students-icon-blue.svg';
import ClassDocsIcon from '../../../assets/img/platform/navigation/class-docs-icon.svg';
import ClassDocsIconBlue from '../../../assets/img/platform/navigation/class-docs-icon-blue.svg';
import HomeIcon from '../../../assets/img/platform/navigation/home-icon.svg';
import HomeIconBlue from '../../../assets/img/platform/navigation/home-icon-blue.svg';
import MediaIcon from '../../../assets/img/platform/navigation/media-icon.svg';
import MediaIconBlue from '../../../assets/img/platform/navigation/media-icon-blue.svg';
import HistoryIcon from '../../../assets/img/platform/navigation/history-icon.svg';
import HistoryIconBlue from '../../../assets/img/platform/navigation/history-icon-blue.svg';
import TeacherIcon from '../../../assets/img/platform/navigation/teacher-icon.svg';
import TeacherIconBlue from '../../../assets/img/platform/navigation/teacher-icon-blue.svg';
import CreditCardIcon from '../../../assets/img/platform/navigation/credit-card-icon.svg';
import CreditCardIconBlue from '../../../assets/img/platform/navigation/credit-card-icon-blue.svg';
import ChatBubble from '../../../assets/img/platform/navigation/chat-bubble.svg';
import ChatBubbleBlue from '../../../assets/img/platform/navigation/basil_chat-outline.svg';
import LogoutIcon from '../../../assets/img/platform/navigation/logout.svg';
import LogoutIconBlue from '../../../assets/img/platform/navigation/logout-blue.svg';
import ConfigIconBlue from '../../../assets/img/platform/navigation/config-icon-blue.svg';
import NotificationsIconBlue from '../../../assets/img/platform/navigation/notifications-icon-blue.svg';
import { useStores } from '../../../stores';
import NotificationBell from '../../NotificationBell';
import DropdownNav from '../../UserNavigation/DropdownNav';
import style from './style.module.scss';
import UnmazeLogo from '../../../assets/img/unmaze/unmaze-logo.svg';

export default function NavbarTeacherMobile() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const location = useLocation();
  const {
    globalStatesStore: { setOpenSiteHomeModal },
    userStore: { userData, handleClearUserData },
    planUnpauseStore:
    {
      useCredits, setUseCredits,
      removeUseCredits,
    },
  } = useStores();

  const fullName = `${userData.firstName} ${userData.lastName}`;

  const [openPlanUnpause, setOpenPlanUnpause] = useState(false);
  const [openIsUnpausing, setOpenIsUnpausing] = useState(false);

  const {
    isPaused,
    isUnpausing,
    isPausingCredits,
  } = userData;

  const history = useHistory();

  function openUnpauseModal() {
    setUseCredits(false);
    setOpenPlanUnpause(true);
  }

  function handleLogout() {
    handleClearUserData();
    history.push('/');
  }

  return (
    <>
      <HStack alignItems="center" w="100%" justifyContent="space-between">
        <Link href="/home/teacher">
          <Image src={UnmazeLogo} w="100px" />
        </Link>
        <Button
          ref={btnRef}
          onClick={onOpen}
          size="sm"
          variant="outline"
          colorScheme="#06097D"
        >
          {isOpen ? 'Close Menu' : 'Menu'}
        </Button>
      </HStack>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerContent className={style['drawer-content']}>
          <DrawerHeader>
            <VStack alignItems="center" w="100%">
              <Avatar src={userData?.avatar} boxSize="52px" />
              <Heading as="h5" size="sm" color="#06097D">
                {fullName}
              </Heading>
            </VStack>
          </DrawerHeader>

          <DrawerBody className={style['drawer-body']}>
            <VStack className={style.navs}>
              <VStack w="100%" spacing={2}>
                <VStack w="100%" spacing={2} pl={5}>
                  <Divider w="85%" borderColor="#06097D" marginTop={3} />
                  <Text
                    alignSelf="start"
                    fontWeight="bold"
                    fontSize="16px"
                    ml={3}
                  >
                    Main
                  </Text>

                  <NavLink
                    to={`/home/${userData.userType.toLowerCase()}`}
                    className={style.a}
                  >
                    <HStack
                      marginLeft="15px"
                      w="85%"
                      borderRadius={5}
                      spacing={2}
                      p={2}
                      bg={location.pathname === `/home/${userData.userType.toLowerCase()}`
                        ? '#06097D' : '#fff'}
                    >
                      <Image
                        src={location.pathname === `/home/${userData.userType.toLowerCase()}`
                          ? HomeIcon : HomeIconBlue}
                        boxSize="24px"
                      />
                      <Text
                        color={location.pathname === `/home/${userData.userType.toLowerCase()}`
                          ? '#fff' : '#06097D'}
                      >
                        Home
                      </Text>
                    </HStack>
                  </NavLink>
                  <Link
                    href="https://drive.google.com/drive/folders/16PAO5DR1bypsft8e7V6NzcGtsQQWCqZg?usp=sharing"
                    target="_blank"
                    rel="noreferrer"
                    className={style.a}
                  >
                    <HStack
                      marginLeft="15px"
                      w="85%"
                      borderRadius={5}
                      spacing={2}
                      p={2}
                    >
                      <Image src={ClassDocsIconBlue} boxSize="24px" />
                      <Text>Class bank</Text>
                    </HStack>
                  </Link>
                  <NavLink
                    to="/history"
                    style={{ width: '100%' }}
                  >
                    <HStack
                      marginLeft="15px"
                      w="85%"
                      borderRadius={5}
                      spacing={2}
                      p={2}
                      bg={location.pathname === '/history'
                        ? '#06097D' : '#fff'}
                    >
                      <Image
                        src={location.pathname === '/history'
                          ? HistoryIcon : HistoryIconBlue}
                        boxSize="24px"
                      />
                      <Text
                        color={location.pathname === '/history'
                          ? '#fff' : '#06097D'}
                      >
                        History
                      </Text>
                    </HStack>
                  </NavLink>
                  <NavLink
                    to="/platform/content-area"
                    style={{ width: '100%' }}
                  >
                    <HStack
                      marginLeft="15px"
                      w="85%"
                      borderRadius={5}
                      spacing={2}
                      p={2}
                      bg={location.pathname === '/platform/content-area'
                        ? '#06097D' : '#fff'}
                    >
                      <Image
                        src={location.pathname === '/platform/content-area'
                          ? MediaIcon : MediaIconBlue}
                        boxSize="24px"
                      />
                      <Text
                        color={location.pathname === '/platform/content-area'
                          ? '#fff' : '#06097D'}
                      >
                        Stud. content area
                      </Text>
                      {/* <Tag fontWeight={600} fontSize={['6px', '7px']}
                       bgColor="#ff1616" color="white">
                        new
                      </Tag> */}
                    </HStack>
                  </NavLink>
                  <NavLink
                    to="/teacher-feedbacks"
                    style={{ width: '100%' }}
                  >
                    <HStack
                      marginLeft="15px"
                      w="85%"
                      borderRadius={5}
                      spacing={2}
                      p={2}
                      bg={location.pathname === '/teacher-feedbacks'
                        ? '#06097D' : '#fff'}
                    >
                      <Image
                        src={location.pathname === '/teacher-feedbacks'
                          ? Star : StarBlue}
                        boxSize="24px"
                      />
                      <Text
                        color={location.pathname === '/teacher-feedbacks'
                          ? '#fff' : '#06097D'}
                      >
                        Feedback
                      </Text>
                      {/* <Tag fontWeight={600} fontSize={['6px', '7px']}
                       bgColor="#ff1616" color="white">
                        new
                      </Tag> */}
                    </HStack>
                  </NavLink>
                  <NavLink
                    to="/studentslist"
                    style={{ width: '100%' }}
                  >
                    <HStack
                      marginLeft="15px"
                      w="85%"
                      borderRadius={5}
                      spacing={2}
                      p={2}
                      bg={location.pathname === '/studentslist'
                        ? '#06097D' : '#fff'}
                    >
                      <Image
                        src={location.pathname === '/studentslist'
                          ? StudentIcon : StudentIconBlue}
                        boxSize="24px"
                      />
                      <Text
                        color={location.pathname === '/studentslist'
                          ? '#fff' : '#06097D'}
                      >
                        My students
                      </Text>
                    </HStack>
                  </NavLink>
                </VStack>
              </VStack>
              <VStack w="100%" pl={5}>
                <Text
                  alignSelf="start"
                  ml={3}
                  mt={3}
                  fontWeight="bold"
                  fontSize="16px"
                >
                  Account
                </Text>
                {/* {(useCredits && isPaused) && (
                <div className={style['open-plan-modal']}>
                  <div className={style['button-container']}>
                    <button
                      className={style['open-modal-button']}
                      onClick={() => openUnpauseModal()}
                      type="button"
                    >
                      Reativar plano
                    </button>
                  </div>
                </div>
                )}
                 {userData.userType === 'Student' && (
              <Button
                className={style.button__green}
                color="white"
                backgroundColor="#47C13C"
                fontSize="sm"
                h="37px"
                marginBottom={5}
                value={!userData.plan ? 'subscribe' : 'renew'}
                onClick={(e) => handleAddHours(e.target.value)}
              >
                {!userData.plan ? 'Ver planos' : 'Solicitar renovação'}
              </Button>

            )} */}
                <NavLink
                  onClick={onClose}
                  to={`/profile/${userData.userType.toLowerCase()}`}
                  style={{ width: '100%' }}
                >
                  <HStack
                    marginLeft="15px"
                    w="85%"
                    borderRadius={5}
                    spacing={2}
                    p={2}
                    bg={location.pathname === `/profile/${userData.userType.toLowerCase()}`
                      ? '#06097D' : '#fff'}
                  >
                    {/* <Image
                      src={location.pathname === `/profile/${userData.userType.toLowerCase()}`
                        ? CreditCardIcon : CreditCardIconBlue}
                      boxSize="24px"
                    />
                    <Text
                      fontWeight={600}
                      color={location.pathname === `/profile/${userData.userType.toLowerCase()}`
                        ? '#fff' : '#06097D'}
                    >
                      Minha assinatura
                    </Text> */}
                  </HStack>
                </NavLink>
                <DropdownNav />
                <HStack
                  w="100%"
                  pl={2}
                >
                  <NotificationBell />
                </HStack>
                <Link
                  href="https://join.slack.com/t/unmaze-teachers/shared_invite/zt-16a041fsn-XAN9BKuYgJa6jxrYrjLtmw"
                  target="_blank"
                  rel="noreferrer"
                  className={style.a}
                >
                  <HStack
                    marginLeft="15px"
                    w="85%"
                    borderRadius={5}
                    spacing={2}
                    p={2}
                  >
                    <Image src={ChatBubbleBlue} boxSize="24px" />
                    <Text>
                      Talk to support
                    </Text>
                  </HStack>
                </Link>
                <HStack
                  as="button"
                  w="100%"
                  h="32px"
                  pl={2}
                  onClick={() => handleLogout()}
                >
                  <Image src={LogoutIconBlue} boxSize="24px" ml="15px" />
                  <Text
                    fontSize="14px"
                    fontWeight={600}
                    w="100%"
                    textAlign="start"
                  >
                    {userData.userType === 'Student' ? 'Sair' : 'Logout'}

                  </Text>
                </HStack>
              </VStack>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
