import { VStack } from '@chakra-ui/react';
import { useEffect } from 'react';
import LogoutIcon from '../../assets/img/logout-btn.svg';
import UnpauseImage from '../../assets/img/warning-human.svg';
import api from '../../services/api';
import { useStores } from '../../stores';
import notify from '../../utils/notify';
import CustomToastError from '../CustomToastError';
import CustomToastSuccess from '../CustomToastSuccess';
import style from './style.module.scss';
import socket from '../../services/socket';

export default function PlanUnpauseModal({ setOpenPlanUnpause, setOpenIsUnpausing }) {
  const {
    userStore: {
      userData, setUserData, token, handleClearUserData,
    },
    planUnpauseStore:
    {
      useCredits, setUseCredits,
      removeUseCredits,
    },
  } = useStores();

  const {
    firstName, lastName, credits, plan,
    classesPerMonth, isPausingCredits,
    pauseDueDate, pauseRequestDate, isPaused, isUnpausing,
    classesTime,
  } = userData;

  const name = `${firstName} ${lastName}`;

  const pausedDate = new Date(pauseRequestDate);
  const currentDate = new Date();

  const hasMoreThan90Days = (currentDate - pausedDate) > 7776000000;

  const translatedTime = {
    business: 'comercial',
    dynamic: 'dinâmico',
  };

  function Logout() {
    handleClearUserData();
  }

  useEffect(() => {
    if (isPaused) {
      if (!isUnpausing) {
        return setOpenPlanUnpause(true);
      }
      if (isUnpausing) {
        return setOpenIsUnpausing(true);
      }
    }
  }, []);

  async function unpausePlanRequest() {
    try {
      const response = await api.post(
        'students/unpause-request',
        {

        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.status > 205) {
        return notify(<CustomToastError
          toastText={response.data}
        />);
      }

      if (response.status === 400) {
        return notify(<CustomToastError
          toastText={response.data}
        />);
      }

      if (response.status === 200) {
        if (response.data === 'Reativação solicitada com sucesso. Aguarde o nosso contato.') {
          setUserData({ ...userData, isUnpausing: true });
          setOpenIsUnpausing(true);
          handleClearUserData();

          return notify(<CustomToastSuccess
            toastText={response.data}
          />);
        }
        setUserData({ ...userData, isPaused: false });
        setOpenPlanUnpause(false);

        return notify(<CustomToastSuccess
          toastText={response.data}
        />);
      }
    } catch (error) {
      const { request } = error;

      if (request) {
        return notify(<CustomToastError
          toastText={request.response}
        />);
      }
    }
  }

  function clickedUseCredits() {
    setUseCredits(true);
    setOpenPlanUnpause(false);
  }

  return (
    <div className={style['modal-container']}>
      <div className={style['modal-content']}>
        {(isPaused && !isUnpausing) ? (
          <div className={style.content}>
            <div className={style['content-top']}>
              <h2>
                Olá,
                {' '}
                {name}
                !
              </h2>
              <div className={style.subtitle}>
                <p>
                  Sua assinatura (
                  <span className={style.blue}>
                    {plan}
                    {' '}
                    {classesPerMonth}
                    {' '}
                    horas/mês | horário
                    {' '}
                    {translatedTime[classesTime]}
                  </span>
                  ) está
                  {' '}
                  <b>pausada</b>
                  .
                </p>
              </div>
            </div>
            <div className={style['content-mid']}>
              <ul>
                <li>
                  Sua assinatura está pausada conforme a sua solicitação do dia
                  {' '}
                  <span className={style.blue}>{pauseRequestDate}</span>
                  .
                </li>
                <li>
                  Como previamente informado,
                  você tem direito a permanecer com sua assinatura em pausa por 60 dias corridos.
                  Sendo assim, estará pausada até o dia
                  {' '}
                  <span className={style.blue}>{pauseDueDate}</span>
                  . Caso não reative até essa data,
                  ela será automaticamente reativada, e o pagamento será retomado.
                  Ao clicar em Reativar Conta, ela será reativada imediatamente.

                </li>
                {!isPausingCredits ? (
                  <VStack w="100%" alignItems="flex-start">
                    {(userData.credits > 0 && !hasMoreThan90Days) ? (
                      <>
                        <li>
                          Você possui
                          {' '}
                          <span className={style.blue}>{credits}</span>
                          {' '}
                          horas em sua conta que optou por não pausar.
                          Você poderá usá-las até a data de renovação,
                          que se encontra na tela inicial.
                          Para utilizá-las clique em “Usar minhas horas”.
                        </li>
                        <li>
                          Ao clicar em reativar conta, ela será reativada imediatamente,
                          e além de poder usar as horas que já
                          possui, você receberá também uma nova fatura no seu e-mail em até
                          24 horas úteis para renovação da assinatura.
                        </li>
                      </>
                    ) : (
                      <li>
                        Para reativar sua assinatura, basta clicar em Reativar Assinatura,
                        e assim, sua conta será reativada em até 12 horas úteis,
                        você também receberá uma fatura para a renovação da assinatura
                        em até 24 horas úteis.
                      </li>
                    )}

                  </VStack>
                ) : (
                  <VStack w="100%" alignItems="flex-start">
                    {(userData.credits > 0 && !hasMoreThan90Days) && (
                      <>
                        <li>
                          Você possui
                          {' '}
                          <span className={style.blue}>{credits}</span>
                          {' '}
                          horas em sua conta que também optou por pausar.
                          Ao reativar (em até 60 dias corridos) essas
                          horas voltam a estar disponíveis pelo mesmo
                          período que estavam no momento da pausa.
                          Confira a data de renovação na tela inicial para se programar
                          e utilizar o seu saldo antes disso.

                        </li>
                        <li>
                          Sua próxima fatura será enviada na data da renovação.
                          Ao clicar em
                          {' '}
                          <b>Reativar Conta</b>
                          , ela será reativada imediatamente.

                        </li>

                      </>
                    )}
                  </VStack>

                )}

                <p className={style.italic}>
                  <b>Obs:</b>
                  {' '}
                  Ignore a questão sobre faturas se você tem bolsa ou
                  se sua empresa paga 100% do seu benefício.
                  Caso tenha dúvidas, nosso time de
                  {' '}
                  <a
                    target="_blank"
                    href="https://api.whatsapp.com/send?phone=5511936185850&text=Ol%C3%A1%2C%20Unmaze!%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20em%20rela%C3%A7%C3%A3o%20%C3%A0%20minha%20pausa%20nas%20aulas%20particulares%20de%20Ingl%C3%AAs"
                    rel="noreferrer"
                    className={style.sac}
                  >
                    Encantamento ao Cliente

                  </a>
                  {' '}
                  estará a disposição.
                </p>
              </ul>
            </div>

            {!isPausingCredits
              ? (
                <div className={style.buttons}>
                  <button
                    className={`${style.btn} ${style['btn-blue-outline']}`}
                    onClick={() => Logout()}
                  >
                    Sair
                    <img
                      src={LogoutIcon}
                      alt="logout"
                    />
                  </button>
                  <button
                    className={`${style.btn} ${style['btn-blue-outline']}`}
                    onClick={() => clickedUseCredits()}
                  >
                    Usar minhas horas
                  </button>
                  <button
                    className={`${style.btn} ${style['btn-blue']}`}
                    type="button"
                    onClick={() => unpausePlanRequest()}
                  >
                    Reativar Conta
                  </button>
                </div>
              )
              : (
                <div className={style.buttons}>
                  <button
                    className={`${style.btn} ${style['btn-blue-outline']}`}
                    onClick={() => Logout()}
                  >
                    Sair
                    <img
                      src={LogoutIcon}
                      alt="logout"
                    />
                  </button>
                  <button
                    className={`${style.btn} ${style['btn-blue']}`}
                    type="button"
                    onClick={() => unpausePlanRequest()}
                  >
                    Reativar Conta
                  </button>
                </div>
              )}
          </div>
        )
          : (
            <div className={style['plan-unpausing']}>
              <div className={style['top-content']}>
                <div>

                  <img
                    src={UnpauseImage}
                    alt=""
                    className={style.human}
                  />
                </div>
                <p>
                  Seu processo de reativação da assinatura está em andamento.
                  Em caso de dúvidas, favor entrar em contato com o nosso time de
                  {' '}
                  <a
                    href="https://api.whatsapp.com/send?phone=5511936185850&text=Ol%C3%A1%2C%20Unmaze!%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20em%20rela%C3%A7%C3%A3o%20%C3%A0%20minha%20pausa%20nas%20aulas%20particulares%20de%20Ingl%C3%AAs"
                    target="_blank"
                    rel="noreferrer"
                    className={style.sac}
                  >
                    Encantamento ao Cliente
                  </a>
                  .

                </p>
              </div>
              <div className={style.buttons}>
                <button
                  className={`${style.btn} ${style['btn-blue-outline']}`}
                  onClick={() => Logout()}
                >
                  Sair
                  <img
                    src={LogoutIcon}
                    alt="logout"
                  />
                </button>
              </div>
            </div>
          )}
      </div>
    </div>
  );
}
